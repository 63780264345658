import { render, staticRenderFns } from "./NewHotel.vue?vue&type=template&id=2a79540c&scoped=true&"
import script from "./NewHotel.vue?vue&type=script&lang=js&"
export * from "./NewHotel.vue?vue&type=script&lang=js&"
import style0 from "./NewHotel.vue?vue&type=style&index=0&id=2a79540c&scoped=true&lang=css&"
import style1 from "./NewHotel.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a79540c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VCol,VRow,VStepper,VStepperContent,VStepperStep})
