<template>
  <div class="custom-content-height">
    <snackbar ref="snackbar" :message="validationMessage"></snackbar>
    <div class="d-block hotel-content black">
      <v-stepper class="my-custom-stepper" v-model="e6" vertical>
        <v-row>
          <v-col cols="3" class="pr-0 bg-color-grey">
            <span v-for="(step, index) in steps">
              <v-stepper-step
                :class="{
                  'mt-5': index === 0,
                  'custom-active-step':
                    setpsValidation[index] && lastFilledIndex > index,
                  'custom-stepper-step': setpsValidation[index],
                }"
                :complete="index === e6 - 1"
                :step="index + 1"
                :key="index"
                :rules="[() => setpsValidation[index]]"
                :editable="
                  lastFilledIndex > index ||
                  (getVendor && getIsVendorUpdate === true)
                "
              >
                <span
                  :class="{
                    'active-step': lastFilledIndex > index,
                    'red--text': !setpsValidation[index],
                  }"
                  >{{ step }}</span
                >
                <small v-if="!setpsValidation[index]" class="red--text"
                  >Please fill the fileds correctly</small
                >
                <div
                  v-if="steps.length > index + 1"
                  class="custom-divider"
                  :style="{ top: !setpsValidation[index] ? '52px' : '43px' }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="3"
                    height="34"
                    viewBox="0 0 3 34"
                  >
                    <path
                      id="Path_589"
                      data-name="Path 589"
                      d="M0,0V31"
                      transform="translate(1.5 1.5)"
                      fill="rgba(0,0,0,0)"
                      stroke="#a7a7a7"
                      stroke-linecap="round"
                      stroke-width="3"
                      stroke-dasharray="0 10"
                    />
                  </svg>
                </div>
              </v-stepper-step>
            </span>
          </v-col>

          <!-- Steps Content (START) -->
          <v-col cols="9">
            <div class="step-content-column">
              <v-stepper-content
                v-for="(step, index) in steps"
                :key="index"
                class="custom-stepper-content"
                :step="index + 1"
              >
                <basic-information
                  @submit="submit"
                  v-if="index === 0"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'basic' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></basic-information>

                <description
                  @submit="submit"
                  v-else-if="index === 1"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'about' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></description>

                <rooms
                  @submit="submit"
                  v-else-if="index === 2"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'rooms' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></rooms>

                <amenities
                  @submit="submit"
                  v-else-if="index === 3"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'amenities' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></amenities>

                <meeting-and-events
                  @submit="submit"
                  v-else-if="index === 4"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'mae' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></meeting-and-events>

                <gallery
                  @submit="submit"
                  v-else-if="index === 5"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'gallery' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></gallery>

                <surroundings-and-activities
                  @submit="submit"
                  v-else-if="index === 6"
                  :form="form"
                  @hotelNextStep="e6 = index + 2"
                  :key="'rent' + index"
                  :index="index"
                  :name="step"
                  :ref="`step${index}`"
                ></surroundings-and-activities>

                <hotel-summary
                  @submit="submit"
                  v-else-if="index === 7 && e6 === 8"
                  :form="form"
                  :key="'summary-page'"
                  :name="step"
                  :ref="`step${index}`"
                ></hotel-summary>
              </v-stepper-content>
            </div>
          </v-col>
          <!-- Steps Content (END) -->
        </v-row>
      </v-stepper>
    </div>
  </div>
</template>

<script>
const BasicInformation = () =>
  import(
    /* webpackChunkName: "basic-information" */ "./forms/BasicInformation"
  );
const Description = () =>
  import(/* webpackChunkName: "description" */ "./forms/Description");
const Rooms = () => import(/* webpackChunkName: "rooms" */ "./forms/Rooms");
const Amenities = () =>
  import(/* webpackChunkName: "amenities" */ "./forms/Amenities");
const MeetingAndEvents = () =>
  import(/* webpackChunkName: "opening-hours" */ "./forms/MeetingAndEvents");
const Gallery = () =>
  import(/* webpackChunkName: "gallery" */ "./forms/Gallery");
const SurroundingsAndActivities = () =>
  import(
    /* webpackChunkName: "surroundings-and-activities" */ "./forms/SurroundingsAndActivities"
  );
const HotelSummary = () =>
  import(/* webpackChunkName: "hotel-summary" */ "./forms/HotelSummary");

import { mapActions, mapMutations, mapGetters } from "vuex";
import Snackbar from "@/components/Snackbar";
export default {
  name: "NewHotel",
  components: {
    Snackbar,
    BasicInformation,
    Description,
    Rooms,
    Amenities,
    MeetingAndEvents,
    Gallery,
    SurroundingsAndActivities,
    HotelSummary,
  },
  props: ["form"],
  data: () => ({
    steps: [
      "Basic Information",
      "Description",
      "Rooms",
      "Amenities",
      "Meeting & Events",
      "Gallery",
      "Surroundings & Activities",
      "Summary",
    ],
    validForm: true,
    validationMessage: "",
    errors: [],
    e6: 1,
    lastFilledIndex: 1,
    setpsValidation: [true, true, true, true, true, true, true, true],
  }),
  computed: {
    ...mapGetters(["getIsVendorUpdate", "getRules", "getVendor"]),
  },
  methods: {
    async submit(type = "draft") {
      if (this.setpsValidation.includes(false)) {
        this.validationMessage = "Please fill the form correctly";
        this.$refs.snackbar.type = "error";
        this.$refs.snackbar.show = true;
        return false;
      }

      this.errors = [];

      if (this.form.email !== null && this.form.email !== "") {
        if (this.getRules.email(this.form.email) === "Invalid e-mail.") {
          this.validationMessage = "Email Field Is Invalid";
          this.$refs.snackbar.type = "error";
          this.$refs.snackbar.show = true;
          return false;
        }
      }

      if (
        this.form.secondary_email !== null &&
        this.form.secondary_email !== ""
      ) {
        if (
          this.getRules.email(this.form.secondary_email) === "Invalid e-mail."
        ) {
          this.validationMessage = "Secondary Email Field Is Invalid";
          this.$refs.snackbar.type = "error";
          this.$refs.snackbar.show = true;
          return false;
        }
      }

      let status = "active";
      if (type === "draft") {
        status = type;
      }
      this.form.status = status;
      let hotel = Object.assign({}, this.form);
      let successMessage = "Hotel has been created successfully.";
      if (this.getIsVendorUpdate === true) {
        successMessage = "Hotel has been updated successfully.";
        this.setVendor(hotel);
        await this.updateVendor({ id: hotel.id });
      } else {
        this.setNewVendor(hotel);
        await this.addVendor();
      }
      this.$emit("showMessage", {
        message: successMessage,
        type: "success",
        show: true,
      });
      this.close();
    },
    close() {
      this.setToggleSlider(false);
    },
    ...mapActions(["addVendor", "updateVendor"]),
    ...mapMutations(["setNewVendor", "setVendor", "setToggleSlider"]),
  },
  watch: {
    e6() {
      for (let i = 0; i < this.e6; i++) {
        this.setpsValidation[i] =
          this.$refs["step" + i] &&
          this.$refs["step" + i][0] &&
          this.$refs["step" + i][0].$refs &&
          this.$refs["step" + i][0].$refs.form
            ? this.$refs["step" + i][0].$refs.form.validate()
            : true;
      }
      if (this.e6 > this.lastFilledIndex) {
        this.lastFilledIndex = this.e6;
      }
    },
  },
};
</script>
<style scoped>
.my-custom-stepper {
  border-radius: 0 !important;
  box-shadow: none;
}
.custom-stepper-step {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  margin-top: 12px;
}

.active-step {
  font-weight: 550;
}

.bg-color-grey {
  background-color: #f9f9f9;
}
.text-color-grey {
  background-color: #f9f9f9;
}
.custom-divider {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 43px;
  margin-left: 35px;
}
.v-application--is-ltr
  .theme--light.v-stepper--vertical
  .v-stepper__content:not(:last-child) {
  border: none;
}

.custom-stepper-content {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.custom-add-hotel-slider {
  z-index: 7;
  position: fixed;
  top: 0;
  right: 0;
  width: 63%;
}
.step-content-column {
  height: 100vh;
  padding-bottom: 75px;
  padding-top: 20px;
  overflow: scroll;
}
.custom-content-height {
  overflow-y: hidden;
  height: 100vh;
  padding-top: 50px;
}
</style>
<style>
.custom-stepper-step .v-stepper__step__step > .mdi-pencil {
  font-size: 15px !important;
  color: #000000 !important;
}
.custom-stepper-step .v-stepper__step__step {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 2px solid #c3c3c3;
  color: #c3c3c3 !important;
  font-size: 14px !important;
}
.custom-stepper-step:hover .v-stepper__step__step {
  border: 1px solid #000000;
  color: #000000 !important;
}
.v-stepper__step.custom-stepper-step.v-stepper__step--active.v-stepper__step--editable
  span.v-stepper__step__step.primary {
  color: #000000 !important;
  font-weight: bold;
}
.custom-active-step span.v-stepper__step__step {
  color: #000000 !important;
  border-color: #000000;
  font-weight: bold;
}
</style>